<template lang="pug">
v-autocomplete(:value="value" label="Course Location" item-value="ID" item-text="name" :loading="loading" :items="items" @input="_valueClicked")
</template>

<script>
import ErrMixin from "@/mixins/errors.mixin"
export default {
  name: 'FilterCourseLocation',
  mixins: [ErrMixin],

  props: {
    value: {
      required: true
    }
  },

  data: () => ({
    loading: false,
    items: []
  }),

  inject: ['loadCourseLocation', 'getCourseLocations'],

  mounted() {
    this.loadData()
  },

  methods: {
    async loadData() {
      try {
        this.loading = true
        await this.loadCourseLocation({cache: true})
        this.items = this.getCourseLocations()
        this.loading = false
      } catch (error) {
        this.loading = false
        this.processErr(error)
      }
    },
    _valueClicked(e) {
      this.$emit('input', e)
    }
  }
}
</script>